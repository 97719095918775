/*!
 * common-user-interface v1.43.3
 * 
 * Copyright adidas 2018
 * Release date: Monday, Sep 17, 2018
 */
/*!
 * common-user-interface v1.43.3
 * 
 * Copyright adidas 2018
 * Release date: Monday, Sep 17, 2018
 */
@font-face {
  src: url(./assets/ds-icon.eot);
  src: url(./assets/ds-icon.eot?#iefix) format("embedded-opentype"), url(./assets/ds-icon.woff) format("woff"), url(./assets/ds-icon.woff2) format("woff2"), url(./assets/ds-icon.ttf) format("truetype"), url(./assets/ds-icon.svg#ds-icon) format("svg");
  font-family: ds-icon; }

.ds .ds-icon {
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
  font-family: ds-icon !important; }

.ds .ds-icon--add-attachment:before {
  content: "\E900"; }

.ds .ds-icon--add-filter:before {
  content: "\E901"; }

.ds .ds-icon--add-ila:before {
  content: "\E902"; }

.ds .ds-icon--add-person:before {
  content: "\E903"; }

.ds .ds-icon--add-to-basket:before {
  content: "\E904"; }

.ds .ds-icon--add-to-collection:before {
  content: "\E905"; }

.ds .ds-icon--align-text-center:before {
  content: "\E906"; }

.ds .ds-icon--align-text-left:before {
  content: "\E907"; }

.ds .ds-icon--align-text-right:before {
  content: "\E908"; }

.ds .ds-icon--app-switch-top-bar:before {
  content: "\E909"; }

.ds .ds-icon--apparel:before {
  content: "\E90A"; }

.ds .ds-icon--arrow-breadcrumb-down:before {
  content: "\E90B"; }

.ds .ds-icon--arrow-breadcrumb-left:before {
  content: "\E90C"; }

.ds .ds-icon--arrow-breadcrumb-right:before {
  content: "\E90D"; }

.ds .ds-icon--arrow-breadcrumb-up:before {
  content: "\E90E"; }

.ds .ds-icon--arrow-chart-down:before {
  content: "\E90F"; }

.ds .ds-icon--arrow-chart-left:before {
  content: "\E910"; }

.ds .ds-icon--arrow-chart-right:before {
  content: "\E911"; }

.ds .ds-icon--arrow-chart-up:before {
  content: "\E912"; }

.ds .ds-icon--arrow-dropdown-down:before {
  content: "\E913"; }

.ds .ds-icon--arrow-dropdown-left:before {
  content: "\E914"; }

.ds .ds-icon--arrow-dropdown-right:before {
  content: "\E915"; }

.ds .ds-icon--arrow-dropdown-up:before {
  content: "\E916"; }

.ds .ds-icon--arrow-gallery-down:before {
  content: "\E917"; }

.ds .ds-icon--arrow-gallery-left:before {
  content: "\E918"; }

.ds .ds-icon--arrow-gallery-right:before {
  content: "\E919"; }

.ds .ds-icon--arrow-gallery-up:before {
  content: "\E91A"; }

.ds .ds-icon--attachment:before {
  content: "\E91B"; }

.ds .ds-icon--attention-big:before {
  content: "\E91C"; }

.ds .ds-icon--attention-small:before {
  content: "\E91D"; }

.ds .ds-icon--axis:before {
  content: "\E91E"; }

.ds .ds-icon--baskets:before {
  content: "\E91F"; }

.ds .ds-icon--batch:before {
  content: "\E920"; }

.ds .ds-icon--binding:before {
  content: "\E921"; }

.ds .ds-icon--buckle-ring:before {
  content: "\E922"; }

.ds .ds-icon--button:before {
  content: "\E923"; }

.ds .ds-icon--calendar:before {
  content: "\E924"; }

.ds .ds-icon--call:before {
  content: "\E925"; }

.ds .ds-icon--chart:before {
  content: "\E926"; }

.ds .ds-icon--checkbox-empty:before {
  content: "\E927"; }

.ds .ds-icon--checkbox-filled:before {
  content: "\E928"; }

.ds .ds-icon--checkmark-released:before {
  content: "\E929"; }

.ds .ds-icon--circle:before {
  content: "\E92A"; }

.ds .ds-icon--circle-text:before {
  content: "\E92B"; }

.ds .ds-icon--close:before {
  content: "\E92C"; }

.ds .ds-icon--close-small:before {
  content: "\E92D"; }

.ds .ds-icon--cloth-hanger:before {
  content: "\E92E"; }

.ds .ds-icon--code-developer:before {
  content: "\E92F"; }

.ds .ds-icon--collection:before {
  content: "\E930"; }

.ds .ds-icon--column-view:before {
  content: "\E931"; }

.ds .ds-icon--comment:before {
  content: "\E932"; }

.ds .ds-icon--confidential-lock:before {
  content: "\E933"; }

.ds .ds-icon--contact-support:before {
  content: "\E934"; }

.ds .ds-icon--copy:before {
  content: "\E935"; }

.ds .ds-icon--costing:before {
  content: "\E936"; }

.ds .ds-icon--court:before {
  content: "\E937"; }

.ds .ds-icon--dashboard:before {
  content: "\E938"; }

.ds .ds-icon--delete:before {
  content: "\E939"; }

.ds .ds-icon--deselect-all:before {
  content: "\E93A"; }

.ds .ds-icon--desi:before {
  content: "\E93B"; }

.ds .ds-icon--diamond:before {
  content: "\E93C"; }

.ds .ds-icon--diamond-text:before {
  content: "\E93D"; }

.ds .ds-icon--directions:before {
  content: "\E93E"; }

.ds .ds-icon--documents-app:before {
  content: "\E93F"; }

.ds .ds-icon--double-checkmark:before {
  content: "\E940"; }

.ds .ds-icon--download:before {
  content: "\E941"; }

.ds .ds-icon--download-excel-file:before {
  content: "\E942"; }

.ds .ds-icon--download-illustrator-file:before {
  content: "\E943"; }

.ds .ds-icon--drill-down:before {
  content: "\E944"; }

.ds .ds-icon--drill-up:before {
  content: "\E945"; }

.ds .ds-icon--edit:before {
  content: "\E946"; }

.ds .ds-icon--ending:before {
  content: "\E947"; }

.ds .ds-icon--enter-fullscreen:before {
  content: "\E948"; }

.ds .ds-icon--exit-fullscreen:before {
  content: "\E949"; }

.ds .ds-icon--expand:before {
  content: "\E94A"; }

.ds .ds-icon--eyelet:before {
  content: "\E94B"; }

.ds .ds-icon--fetch:before {
  content: "\E94C"; }

.ds .ds-icon--file:before {
  content: "\E94D"; }

.ds .ds-icon--filter:before {
  content: "\E94E"; }

.ds .ds-icon--finished-component-trim:before {
  content: "\E94F"; }

.ds .ds-icon--finishing:before {
  content: "\E950"; }

.ds .ds-icon--flex:before {
  content: "\E951"; }

.ds .ds-icon--folder:before {
  content: "\E952"; }

.ds .ds-icon--follower:before {
  content: "\E953"; }

.ds .ds-icon--following:before {
  content: "\E954"; }

.ds .ds-icon--footwear:before {
  content: "\E955"; }

.ds .ds-icon--from:before {
  content: "\E956"; }

.ds .ds-icon--gallery:before {
  content: "\E957"; }

.ds .ds-icon--gender:before {
  content: "\E958"; }

.ds .ds-icon--gim:before {
  content: "\E959"; }

.ds .ds-icon--grab-object:before {
  content: "\E95A"; }

.ds .ds-icon--half-heart-left:before {
  content: "\E95B"; }

.ds .ds-icon--half-heart-right:before {
  content: "\E95C"; }

.ds .ds-icon--half-star-left:before {
  content: "\E95D"; }

.ds .ds-icon--half-star-right:before {
  content: "\E95E"; }

.ds .ds-icon--heat-transfer:before {
  content: "\E95F"; }

.ds .ds-icon--home:before {
  content: "\E960"; }

.ds .ds-icon--ila:before {
  content: "\E961"; }

.ds .ds-icon--ila-copy:before {
  content: "\E962"; }

.ds .ds-icon--image:before {
  content: "\E963"; }

.ds .ds-icon--information-big:before {
  content: "\E964"; }

.ds .ds-icon--information-small:before {
  content: "\E965"; }

.ds .ds-icon--interior:before {
  content: "\E966"; }

.ds .ds-icon--invisible:before {
  content: "\E967"; }

.ds .ds-icon--invite-for-coffee:before {
  content: "\E968"; }

.ds .ds-icon--invite-for-facecall:before {
  content: "\E969"; }

.ds .ds-icon--lable:before {
  content: "\E96A"; }

.ds .ds-icon--leather:before {
  content: "\E96B"; }

.ds .ds-icon--line:before {
  content: "\E96C"; }

.ds .ds-icon--link:before {
  content: "\E96D"; }

.ds .ds-icon--list-view:before {
  content: "\E96E"; }

.ds .ds-icon--location:before {
  content: "\E96F"; }

.ds .ds-icon--loopclosure:before {
  content: "\E970"; }

.ds .ds-icon--love:before {
  content: "\E971"; }

.ds .ds-icon--mail:before {
  content: "\E972"; }

.ds .ds-icon--manequin:before {
  content: "\E973"; }

.ds .ds-icon--manual:before {
  content: "\E974"; }

.ds .ds-icon--material-structure:before {
  content: "\E975"; }

.ds .ds-icon--materials-app:before {
  content: "\E976"; }

.ds .ds-icon--menu:before {
  content: "\E977"; }

.ds .ds-icon--minus:before {
  content: "\E978"; }

.ds .ds-icon--more:before {
  content: "\E979"; }

.ds .ds-icon--move:before {
  content: "\E97A"; }

.ds .ds-icon--not-released:before {
  content: "\E97B"; }

.ds .ds-icon--notifications:before {
  content: "\E97C"; }

.ds .ds-icon--open-in-desi:before {
  content: "\E97D"; }

.ds .ds-icon--others-misc:before {
  content: "\E97E"; }

.ds .ds-icon--overviews-app:before {
  content: "\E97F"; }

.ds .ds-icon--pad:before {
  content: "\E980"; }

.ds .ds-icon--people-app:before {
  content: "\E981"; }

.ds .ds-icon--perm-media:before {
  content: "\E982"; }

.ds .ds-icon--person-pin:before {
  content: "\E983"; }

.ds .ds-icon--pipe:before {
  content: "\E984"; }

.ds .ds-icon--plus:before {
  content: "\E985"; }

.ds .ds-icon--polymer:before {
  content: "\E986"; }

.ds .ds-icon--present-to-all:before {
  content: "\E987"; }

.ds .ds-icon--presets:before {
  content: "\E988"; }

.ds .ds-icon--print:before {
  content: "\E989"; }

.ds .ds-icon--products:before {
  content: "\E98A"; }

.ds .ds-icon--products-box:before {
  content: "\E98B"; }

.ds .ds-icon--profile:before {
  content: "\E98C"; }

.ds .ds-icon--protoordering:before {
  content: "\E98D"; }

.ds .ds-icon--puller:before {
  content: "\E98E"; }

.ds .ds-icon--purchase-package:before {
  content: "\E98F"; }

.ds .ds-icon--questionmark-big:before {
  content: "\E990"; }

.ds .ds-icon--questionmark-small:before {
  content: "\E991"; }

.ds .ds-icon--quote:before {
  content: "\E992"; }

.ds .ds-icon--radiobox-empty:before {
  content: "\E993"; }

.ds .ds-icon--radiobox-filled:before {
  content: "\E994"; }

.ds .ds-icon--redlining:before {
  content: "\E995"; }

.ds .ds-icon--redo:before {
  content: "\E996"; }

.ds .ds-icon--reload:before {
  content: "\E997"; }

.ds .ds-icon--reply:before {
  content: "\E998"; }

.ds .ds-icon--report:before {
  content: "\E999"; }

.ds .ds-icon--restore-backup:before {
  content: "\E99A"; }

.ds .ds-icon--rivet:before {
  content: "\E99B"; }

.ds .ds-icon--search:before {
  content: "\E99C"; }

.ds .ds-icon--search-applied:before {
  content: "\E99D"; }

.ds .ds-icon--select-all:before {
  content: "\E99E"; }

.ds .ds-icon--settings:before {
  content: "\E99F"; }

.ds .ds-icon--share:before {
  content: "\E9A0"; }

.ds .ds-icon--shop:before {
  content: "\E9A1"; }

.ds .ds-icon--sidebar:before {
  content: "\E9A2"; }

.ds .ds-icon--snapbutton:before {
  content: "\E9A3"; }

.ds .ds-icon--square:before {
  content: "\E9A4"; }

.ds .ds-icon--square-text:before {
  content: "\E9A5"; }

.ds .ds-icon--star:before {
  content: "\E9A6"; }

.ds .ds-icon--stop-watch:before {
  content: "\E9A7"; }

.ds .ds-icon--sustainable:before {
  content: "\E9A8"; }

.ds .ds-icon--switch-axes:before {
  content: "\E9A9"; }

.ds .ds-icon--sync:before {
  content: "\E9AA"; }

.ds .ds-icon--synthetic:before {
  content: "\E9AB"; }

.ds .ds-icon--tag:before {
  content: "\E9AC"; }

.ds .ds-icon--tape:before {
  content: "\E9AD"; }

.ds .ds-icon--text-bold:before {
  content: "\E9AE"; }

.ds .ds-icon--text-crossed:before {
  content: "\E9AF"; }

.ds .ds-icon--text-italic:before {
  content: "\E9B0"; }

.ds .ds-icon--text-size:before {
  content: "\E9B1"; }

.ds .ds-icon--text-tool:before {
  content: "\E9B2"; }

.ds .ds-icon--text-underlined:before {
  content: "\E9B3"; }

.ds .ds-icon--textile:before {
  content: "\E9B4"; }

.ds .ds-icon--thread:before {
  content: "\E9B5"; }

.ds .ds-icon--tiecord:before {
  content: "\E9B6"; }

.ds .ds-icon--tile-view:before {
  content: "\E9B7"; }

.ds .ds-icon--time:before {
  content: "\E9B8"; }

.ds .ds-icon--to:before {
  content: "\E9B9"; }

.ds .ds-icon--toolbox-app:before {
  content: "\E9BA"; }

.ds .ds-icon--training-app:before {
  content: "\E9BB"; }

.ds .ds-icon--trapeze:before {
  content: "\E9BC"; }

.ds .ds-icon--trapeze-text:before {
  content: "\E9BD"; }

.ds .ds-icon--triangle:before {
  content: "\E9BE"; }

.ds .ds-icon--triangle-text:before {
  content: "\E9BF"; }

.ds .ds-icon--unavailable-reference-data:before {
  content: "\E9C0"; }

.ds .ds-icon--unconfidential-unlock:before {
  content: "\E9C1"; }

.ds .ds-icon--undo:before {
  content: "\E9C2"; }

.ds .ds-icon--unlink-items:before {
  content: "\E9C3"; }

.ds .ds-icon--upload:before {
  content: "\E9C4"; }

.ds .ds-icon--video:before {
  content: "\E9C5"; }

.ds .ds-icon--visible:before {
  content: "\E9C6"; }

.ds .ds-icon--warning:before {
  content: "\E9C7"; }

.ds .ds-icon--warp-knitted:before {
  content: "\E9C8"; }

.ds .ds-icon--weft-knitted:before {
  content: "\E9C9"; }

.ds .ds-icon--wizard:before {
  content: "\E9CA"; }

.ds .ds-icon--woven:before {
  content: "\E9CB"; }

.ds .ds-icon--zipper:before {
  content: "\E9CC"; }

.ds .ds-icon--zoom-in:before {
  content: "\E9CD"; }

.ds .ds-icon--zoom-out:before {
  content: "\E9CE"; }

.ds .ds-icon--bullet-points:before {
  content: "\E9CF"; }

.ds .ds-icon--drop:before {
  content: "\E9D0"; }

.ds .ds-icon--enumeration:before {
  content: "\E9D1"; }

.ds .ds-icon--eraser:before {
  content: "\E9D2"; }

.ds .ds-icon--hieroglyphs:before {
  content: "\E9D3"; }

.ds .ds-icon--indent:before {
  content: "\E9D4"; }

.ds .ds-icon--outdent:before {
  content: "\E9D5"; }

.ds .ds-icon--paragraph:before {
  content: "\E9D6"; }

.ds .ds-icon--smiley:before {
  content: "\E9D7"; }

.ds .ds-icon--table:before {
  content: "\E9D8"; }

.ds .ds-icon--workflow:before {
  content: "\E9D9"; }

.ds .ds-icon--add-folder:before {
  content: "\E9DA"; }

.ds .ds-icon--add-material:before {
  content: "\E9DB"; }

.ds .ds-icon--add-patch:before {
  content: "\E9DC"; }

.ds .ds-icon--advanced-visualisation:before {
  content: "\E9DD"; }

.ds .ds-icon--check-file:before {
  content: "\E9DE"; }

.ds .ds-icon--column-collapsed:before {
  content: "\E9DF"; }

.ds .ds-icon--column-Expand:before {
  content: "\E9E0"; }

.ds .ds-icon--column-filter:before {
  content: "\E9E1"; }

.ds .ds-icon--combine-layers:before {
  content: "\E9E2"; }

.ds .ds-icon--delete-empty-layers:before {
  content: "\E9E3"; }

.ds .ds-icon--engrave:before {
  content: "\E9E4"; }

.ds .ds-icon--export-handover-pack:before {
  content: "\E9E5"; }

.ds .ds-icon--export:before {
  content: "\E9E6"; }

.ds .ds-icon--hide-bounding-box:before {
  content: "\E9E7"; }

.ds .ds-icon--mirror-model:before {
  content: "\E9E8"; }

.ds .ds-icon--mirror:before {
  content: "\E9E9"; }

.ds .ds-icon--pause:before {
  content: "\E9EA"; }

.ds .ds-icon--play:before {
  content: "\E9EB"; }

.ds .ds-icon--punch:before {
  content: "\E9EC"; }

.ds .ds-icon--rearange-layers:before {
  content: "\E9ED"; }

.ds .ds-icon--recieve-mail:before {
  content: "\E9EE"; }

.ds .ds-icon--rename-layers-according-to-order:before {
  content: "\E9EF"; }

.ds .ds-icon--repair-curve:before {
  content: "\E9F0"; }

.ds .ds-icon--send-mail:before {
  content: "\E9F1"; }

.ds .ds-icon--stop:before {
  content: "\E9F2"; }

.ds .ds-icon--switch-inside-outside:before {
  content: "\E9F3"; }

.ds .ds-icon--swithes-to-advanced-visualisation:before {
  content: "\E9F4"; }

.ds .ds-icon--select-related:before {
  content: "\E9F5"; }

.ds .ds-icon--three-d:before {
  content: "\E9F6"; }

/*# sourceMappingURL=ds-icon.css.map*/
